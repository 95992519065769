<template>
    <div>
        <!-- Header starts -->
        <div class="container-fluid form-inline header_div" >
            <a href="/" class="logo_link"><img src="/vstatic/images/logo.png" class="logo" /></a>
            <a href="/vlogout" class="btn btn-secondary float-right">Sign Out</a>
        </div>
        <!-- Header ends -->
        <div class="error_page_container" v-if="((! just_started) && ((error.statusCode != 404) || (redirected)))">
            An error happened.<br/>
            {{ error.statusMessage }} (error code: {{ error.statusCode }}).<br/>
            Contact your support team for assistance.
            <br/><br/>
            <!--I didn't want to display the button.  If they want to go to the home page, let them click on the logo or reload the page manually.-->
            <!--
            <button @click="handleError" class="btn btn-secondary btn-outline-primary">Go Home</button>
            -->
        </div>

        <div class="error_page_container" v-if="just_started">
            Waiting for backend services.
        </div>
    </div>
</template>
  
<script setup lang="ts">
/*
See:
https://nuxt.com/docs/getting-started/error-handling#rendering-an-error-page
https://nuxt.com/docs/getting-started/error-handling#example

If we want to simulate an error using createError, use:
throw createError({ statusCode: 404, statusMessage: 'Page Not Found' });

If we want to simulate an error using showError, use:
showError({ statusCode: 404, statusMessage: "Page Not Found" })

This error page can also be used to handle any HTTP error, and can also be used to handle 
any application error.  In that case, we can just use createError and showError as 
shown above.

Read the rest of 
https://nuxt.com/docs/getting-started/error-handling
*/
const props = defineProps({
  error: Object
})

let env_state = useEnvState();
let env = env_state.value;
if ((typeof(env) == 'undefined') || (Object.keys(env).length === 0)) {
  env = await getEnv();
}
let enforce_login = await getEnforceLogin();

let error = props.error;
const route = useRoute();
if ((error.statusCode == 401) && (enforce_login == true)){
    await navigateTo('/vlogin');
}

let just_started = false;
if ( error.statusCode == 500 ) {
    let server_start_time = env.server_start_time;
    let now = (new Date()).getTime() / 1000;
    if (now - parseInt(server_start_time) < 4 * 60) {
        // The server was restarted within 4 minutes
        just_started = true;
    }
}

const redirected = (route?.query?.redirected || '').trim().toLowerCase() == 'y';
if (typeof(window) != 'undefined') {
    hideWaitPage();
    if (( error.statusCode == 500 ) && (just_started == true)) {
        /*
        When we restart, it often that the nuxt server comes up first, and the req-router service is still lagging behind.
        If the user gets impatient and reload the page, we previously display the error 500 page, which is not very friendly,
        and it make us look bad.  So we try to detect if the server was just restarted, and display a different message 
        "Waiting for backend services", and here we automatically reload the page.

        On production, we do not have HMR on so the browser should not detect server restarted and reload the page automatically.
        So the user should not go into here unless the user manually reload the page.

        I am not entirely sure when this error page is display.  I think it is only display when there is a page transition.
        I think what we are doing here is safe, but if it is not, then we do not use setTimeout to call window.location.reload.
        Instead, we can research further on when this error page is displayed, change the "Waiting for backend services" to 
        longer message, ask the user check the task that he / she was working on, re-perform the edit, etc..
        */
        setTimeout(() => {
            window.location.reload();
        }, 3000);

    } else {
        console.warn(`An error happened somewhere, and was raised to the error page, exception:`, error);
        if ((! redirected) && (error.statusCode == 404) && (typeof(window) != 'undefined'))  {
            let url = error.url;
            if (url.indexOf('?') > -1) {
                url = url + '&redirected=Y';
            } else {
                url = url + '?redirected=Y'
            }
            //navigateTo(url);
            window.location.href = url;
        }
    }
} else {
    if ((typeof(error.statusCode) != 'undefined') && (error.statusCode >= 500)) {
        console.log("An error happened:", error);
    }
}

const handleError = () => {
    //clearError({ redirect: '/' });
    window.location.href = '/';
}
</script>

<style scoped>
.header_div {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: right;
  display: flex;
  border-bottom: 1px solid #ccc;
  background-color: white;
}

.header_div .logo {
  width: 200px;
}
.header_div button,
.header_div a {
  margin: 5px 10px;
}
.error_page_container {
    margin-top: 10px;
    text-align: center;
}
.float-right {
    position: absolute;
    right: 0px;
}
</style>

